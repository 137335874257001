import React, {createContext, useContext, useEffect, useMemo, useState} from "react";
import {CreateRequestFooter, useCreateRequestContext} from "../../../containers/AdminFlow/CreateAppointment";
import './PaymentStep.style.scss'
import {Input} from "../../Common/Input/Input";
import {Radiobutton} from "../../Common/RadioButton/Radiobutton";
import {CheckboxV2} from "../../Common/CheckboxV2";
import {COLORS_CHECKBOX} from "../../Common/CheckboxV2/constants";
import {validationRequiredField} from "../../../utils/validation";
import {filterObj, lengthObj} from "../../../utils/common";
import {InfoWithMessage} from "../../Common/infoWithMessage/infoWithMessage";
import {colors} from "../../../assets/colors/colors";
import {Typography} from "../../Common/Typography/Typography";

export const paymentStepInformedOptions = [{value: 'patient', label: 'Notify patient'}, {
    value: 'organizer',
    label: 'Notify admin'
}];

export const PaymentStepContext = createContext(null);
export const usePaymentStepContext = () => useContext(PaymentStepContext);
export const usePaymentStepState = () => {
    const [price, serPrice] = useState('');
    const [informed, setInformed] = useState(undefined);

    return useMemo(() => ({
        price: price,
        serPrice: serPrice,
        informed: informed,
        setInformed: setInformed,
    }), [price, informed])
}
export const PaymentStep = () => {
    const [errors, setErrors] = useState({});
    const {onNext} = useCreateRequestContext();
    const {
        price,
        informed,
        serPrice,
        setInformed,
    } = usePaymentStepContext();

    const validateForm = () => {
        let errors = {}
        errors.price = validationRequiredField(price);
        errors.informed = informed === undefined;
        return filterObj(errors);
    };

    const onNextPressed = () => {
        const errors = validateForm();
        if (!lengthObj(errors)) {
            onNext();
        } else {
            setErrors(errors);
        }
    }

    return <div className='payment-step'>
        <Input
            placeholder={'Price $'}
            value={price}
            type="number"
            maxLength="200"
            error={errors["price"]}
            onChange={(_, value) => {
                setErrors((prev) => ({...prev, 'price': null}))
                serPrice(value);
            }
            }
        />

        <div className="payment-step_informed-options">
            <div className="payment-step_informed-options_wrapper">
                {paymentStepInformedOptions.map((i, index) => (<Radiobutton
                    key={index}
                    label={i.label}
                    error={errors["informed"]}
                    onClick={() => {
                        setErrors((prev) => ({...prev, 'informed': null}))
                        setInformed(i)
                    }
                    }
                    className=""
                    checked={informed?.value === i?.value}
                />))}
            </div>
            <Radiobutton
                label="No notification"
                error={errors["informed"]}
                onClick={() => {
                    setErrors((prev) => ({...prev, 'informed': null}))
                    setInformed(null)
                }
                }
                className=""
                checked={informed === null}
            />
        </div>
        <CreateRequestFooter onNext={onNextPressed}/>
    </div>
}