import React from "react";
import cn from "classnames";
import { STATUSES_EVENTS } from "../../../constants/Doctors";
import { DECLINED } from "../../../constants";
import { Group } from "@material-ui/icons";
import {getTimeInFormat12} from "../../../utils/date";
import {Tooltip} from "../../Common/Tooltip";

export const CalendarEventDoctor = props => {
  const membersAmount = props.event.data.requests_in_group;
  const isGroup = membersAmount >= 1;
  const isMultiple = props.event.data.is_grouped;
  const requestsAmount = props.event.data.requests_count;
  const aliasRequest = props.user.uuid !== props.event.data.doctor_uuid;
  const acceptedMy =
    !aliasRequest &&
    props.user.uuid === props.event.data.doctor_uuid &&
    props.event.data.status === STATUSES_EVENTS.accepted;
  const assignedMy =
    !aliasRequest &&
    props.user.uuid === props.event.data.doctor_uuid &&
    props.event.data.status === STATUSES_EVENTS.assigned;
  const assignedNotMy = aliasRequest;
  const isDeclined =
    props.event.data.service_request_status &&
    props.event.data.service_request_status.toLowerCase() === DECLINED;
  const notSelected = props.selectedEvent?.uuid && props.selectedEvent?.uuid !== props.event.data.uuid;
  const onClick = () => props.action(props.event.data);

    const date = `${getTimeInFormat12(props.event.start)} - ${getTimeInFormat12(props.event.end)}`
  return (
      <Tooltip title={date}>
    <div
      className={cn("shedule-container-event-doctor", {
        "shedule-container-event-doctor_accepted-my": acceptedMy && !isDeclined,
        "shedule-container-event-doctor_assigned-my": assignedMy && !isDeclined,
        "shedule-container-event-doctor_assigned-not-my":
          assignedNotMy && !isDeclined,
        "shedule-container-event-doctor_declined": isDeclined,
        "shedule-container-event-doctor_not-selected": notSelected
      })}
      onClick={onClick}
    >
      {isMultiple ? (
        <>
          <div className="shedule-container-event-doctor_amount">
            <span className="shedule-container-event-doctor_amount-wrapper">
              {`${requestsAmount} requests`}
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="shedule-container-event-doctor_header">
            {isGroup && (
              <div className="shedule-container-event-doctor_icon">
                <Group style={{ fontSize: 16 }} />
                <span>({membersAmount})</span>
              </div>
            )}
            <span className="shedule-container-event-doctor_status">
              {assignedNotMy
                ? props.event.data.doctor_initials
                : props.event.data.service_request_status
              }
            </span>
          </div>
          <span className="shedule-container-event-doctor_patient">
            {props.event.data.patient_name}
          </span>
        </>
      )}
      {props.event.data?.service_request_chat?.has_unread_messages && !aliasRequest && <div className='shedule-container-event-doctor_is-mess'/>}
    </div>
      </Tooltip>
  );
};
